@import 'mixins.scss';

.hrStyle {
  margin-top: 0;
  margin-bottom: 0;
}

.wrapper {
  display: grid;
  grid-template-columns: auto;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 12px;
}

.tripTimeline {
  padding: 8px;
  display: grid;
  grid-template-columns: auto;
  
  .trip {
    display: grid;
    grid-template-columns: 12px auto;
    grid-template-rows: auto;
    align-items: center;
    
    .dot {
      grid-column: 1;
      grid-row: 1;
      border: 3px solid #2976fa;
      height: 6px;
      width: 6px;
      border-radius: 4px;
      justify-self: center;
      align-self: center;
      z-index: 1;
      color: #2976fa;
      
      &_start {
        @extend .dot;
        border: 3px solid #fd0000;
      }
      
      &_end {
        @extend .dot;
        border: 3px solid #18ab47;
      }
      
      &_stop {
        grid-column: 1;
        grid-row: 1;
        height: 12px;
        width: 12px;
        justify-self: center;
        align-self: center;
        color: #2976fa;
        z-index: 1;
        text-align: center;
        line-height: 12px;
        font-weight: 600;
      }
      
      .dotContent {
        font-size: 6px;
        font-weight: 700;
        line-height: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    
    .line {
      grid-column: 1;
      grid-row: 1;
      height: 12px;
      width: 3px;
      background-color: rgba(0, 0, 0, 0.2);
      justify-self: center;
      align-self: start;
      z-index: 2;
      
      &_up {
        @extend .line;
        align-self: start;
      }
      
      &_down {
        @extend .line;
        align-self: end;
      }
    }
    
    .addressTitle {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      color: #000;
      margin-left: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    
    .addressExtra {
      font-size: 12px;
      color: #888B8C;
      margin-left: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.cancelRide {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  justify-self: end;
  margin-right: 4px;
  margin-top: 8px;
}

.tooltipWrapper {
  display: grid;
  row-gap: 4px;
  font-size: 1rem;
  font-weight: 700;
}

.tooltipBtn {
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;

  &_no {
    @extend .tooltipBtn;
    background-color: unset;
    border: 1px solid #454545;
  }

  &_yes {
    @extend .tooltipBtn;
    background-color: red;
    border: 1px solid red;
    color: white;
  }
}