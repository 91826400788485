[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  /* Higher than React Leaflet map */
  z-index:1001
}

[data-rsbs-header] {
  padding-bottom: 1rem;
}

[data-rsbs-header]::before {
  width: 46px;
  height: 2px;
  background-color: #C7C7C7;
}

[data-rsbs-footer] {
  padding-top: 0px;
  box-shadow: none;
  padding-bottom: calc(8px + env(safe-area-inset-bottom));
}

.footerBar {
  margin-right: auto;
  margin-left: auto;
  height: 2px;
  width: 92px;
  margin-top: 8px;
  margin-bottom: 4px;
  border-radius: 2px;
  background-color: #454545;
}