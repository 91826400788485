.preRequest {
  display: grid;
  grid-template-rows: repeat(4, max-content);
  row-gap: 0.5rem;
}

.loading {
  height: inherit;
  width: inherit;
  display: grid;
  justify-content: center;
  align-content: center;
}

.driverNotFound {
  display: grid;
  grid-template-rows: repeat(4, max-content);
  row-gap: 0.5rem;
}

.driverCancel {
  display: grid;
  grid-template-rows: repeat(4, max-content);
  row-gap: 0.5rem;
}

.instructions {
  margin-left: 0.5rem;
  display: grid;
  grid-template-columns: max-content auto;
  column-gap: 4px;
  color: #888B8C;
}

.notice {
  text-align: center;
  justify-self: center;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #888B8C;
}

.button {
  justify-self: center;

  background-color: #18AB47;
  border-radius: 3px;
  border: none;
  height: 36px;
  width: 154px;

  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #FFFEFE;
}

.terms {
  justify-self: center;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #888B8C;
}
