.page {
  padding: 0 1rem;
  // height: 100vh;
  // overflow: hidden;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(8, max-content) auto;
}

.logo {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.title {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #454545;
}

.divider {
  background-color: #AFAFAF;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 0;
  overflow: visible;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 0;
  border-top: 1px solid #AFAFAF;
}

.time {
  margin-left: 1rem;
  display: grid;
  grid-template-columns: auto;

  &_title {
    margin-left: 22px;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #888B8C;
  }

  &_date {
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 8px;
    align-items: center;

    &_dateTime {
      font-family: 'Nunito Sans', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      color: #161616;
    }
  }
}

.timeline {
  margin-top: 1rem;
  margin-left: 1rem;
  display: grid;

  &_trip {
    display: grid;
    grid-template-columns: max-content auto;
    grid-template-rows: auto;
    align-items: center;
    height: 4rem;

    &_line {
      &_up {
        grid-column: 1;
        grid-row: 1;
        height: 22px;
        width: 3px;
        background-color: rgba(0, 0, 0, 0.2);
        justify-self: center;
        z-index: 2;

        align-self: start;
      }

      &_down {
        grid-column: 1;
        grid-row: 1;
        height: 22px;
        width: 3px;
        background-color: rgba(0, 0, 0, 0.2);
        justify-self: center;
        z-index: 2;

        align-self: end;
      }
    }

    &_dot {
      &_start {
        grid-column: 1;
        grid-row: 1;
        height: 6px;
        width: 6px;
        border-radius: 3px;
        justify-self: center;
        align-self: center;
        z-index: 1;
        border: 3px solid #ed0000;
      }

      &_end {
        grid-column: 1;
        grid-row: 1;
        height: 6px;
        width: 6px;
        border-radius: 3px;
        justify-self: center;
        align-self: center;
        z-index: 1;
        border: 3px solid #18ab47;
      }

      &_stop {
        grid-column: 1;
        grid-row: 1;
        height: 6px;
        width: 6px;
        border-radius: 3px;
        justify-self: center;
        align-self: center;
        z-index: 1;
        border: 3px solid #2976fa;
      }
    }

    &_address {
      margin-left: 10px;
      line-height: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &_type {
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #888B8C;
      }

      &_first {
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #161616;
      }

      &_last {
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #888B8C
      }
    }
  }
}

.vehicle {
  margin-top: 1rem;
  margin-left: 1rem;
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  column-gap: 8px;

  &_title {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #888B8C;
  }

  &_type {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    grid-area: 2/2;
  }

  &_description {
    grid-area: 3/2;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #888B8C;
  }
}
