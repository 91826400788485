@import 'mixins.scss';

.loading {
  height: inherit;
  width: inherit;
  display: grid;
  justify-content: center;
  align-content: center;
}

.hrStyle {
  margin-top: 0;
  margin-bottom: 0;
}

.wrapper {
  border-radius: 16px 16px 0 0;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 8px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(4,max-content);
}

.driverInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
}

.wrapperImg {
  display: flex;
  align-items: center;
}

.driverImage {
  z-index: 2;
  position: relative;
  height: 50px;
  border-radius: 6px;
  object-fit: contain;
  image-rendering: auto;
  image-rendering: -webkit-optimize-contrast;
}

.carImage {
  margin-left: -8px;
  z-index: 1;
  position: relative;
  height: 52px;
  object-fit: contain;
  image-rendering: auto;
  image-rendering: -webkit-optimize-contrast;
}

.wrapperLeftInfo {
  display: grid;
}

.vehicleNumber {
  font-weight: 600;
  font-size: 16px;
  
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
}

.vehicleModel {
  font-weight: 600;
  font-size: 16px;
  
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
}

.driverName {
  font-size: 24px;
  font-weight: 600;
  
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  overflow: hidden;
}

.wrapperTimeCall {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.callDriver {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-column: 2;
}
