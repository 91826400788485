.loading {
  height: inherit;
  width: inherit;
  background-color: #f7f8f8;
  display: grid;
  justify-content: center;
  align-content: center;
}

.noRide {
  background-color: #f7f8f8;
	height: inherit;
	display: grid;
	justify-content: center;
	align-content: center;

  &_info {
    text-align: center;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 700;
    color: #0e342b;
    letter-spacing: 0.38px;
  }
}
